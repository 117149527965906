<template>
  <header
    id="header"
    role="banner"
  >
    <div class="keyVisual">
      <div id="siteTitle">
        <h1 id="siteLogo">
          <a href="./"><img
            src="@/assets/img/logo.png"
            alt="軽井沢碓氷峠 信濃国特別神社 熊野皇大神社"
          ></a>
        </h1>
        <picture id="copy">
          <source
            srcset="@/assets/img/description_pc.svg"
            media="(min-width: 1000px)"
          >
          <img src="@/assets/img/description_sp.svg">
        </picture>
      </div>
      <div class="head_icon">
        <div
          id="miko"
          class="head_gifani"
        >
          <img
            src="@/assets/img/miko.gif"
            alt="巫女"
          >
        </div>
        <div
          id="crow"
          class="head_gifani"
        >
          <img
            src="@/assets/img/crow.gif"
            alt="八咫烏"
          >
        </div>
        <div
          id="guji"
          class="head_gifani"
        >
          <img
            src="@/assets/img/guji.gif"
            alt="宮司"
          >
        </div>
      </div>
    </div>
    <!-- {{ reffaral }}{{ locale }} -->
  </header>
</template>

<script>
import gsap from 'gsap'

export default {

  props: {
    reffaral: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      audio: new Audio(require('@/assets/sound1.ogg'))
    }
  },

  mounted () {
    const tl = gsap.timeline()

    /* console.log(this.locale) */

    /* miko */
    gsap.set('#miko', {
      x: -1000
    })

    /* guji */
    gsap.set('#guji', {
      x: 1000
    })
    addEventListener('keydown', this.moveChara)
    /* title */
    /* eslint-desable */
    /*
    gsap.set('#siteLogo', {
      y: -400
    })
    */
    /* eslint-desable */

    /* copy */
    gsap.set('#copy', {
      autoAlpha: 0
    })

    /* timeline */
    tl.to('#miko', {
      delay: 1.5,
      keyframes: [
        {
          x: 0,
          duration: 4,
          ease: 'power4.out'
        },
        {
          x: 100,
          duration: 4,
          ease: 'power4.out'
        }
      ]
    })
      .to('#guji', {
        keyframes: [
          {
            x: 0,
            duration: 4,
            ease: 'power4.out'
          },
          {
            x: -100,
            duration: 4,
            ease: 'power4.out'
          }
        ]
      }, '<')
      .to('#crow', {
        x: 600,
        y: -1000,
        duration: 2,
        scale: 3,
        display: 'none',
        ease: 'power4.inOut'
      }, '-=3')
      .from('#siteLogo', {
        y: -300,
        opacity: 0,
        duration: 10,
        ease: 'power4.out'
      /* onComplete: () => this.audio.play() */
      })
      .to('#copy', {
        autoAlpha: 1,
        scale: 1.3,
        y: 1000
      }, '-=3')
  },

  methods: {
    moveChara (e) {
      const code = e.keyCode
      /* console.log(e.keyCode) */
      switch (code) {
      case 37:
        gsap.to('#miko', {
          x: '-=100'
        })
        break
      case 39:
        gsap.to('#miko', {
          x: '+=100'
        })
        break
      case 38:
        gsap.fromTo('#miko',
          {
            y: '-=200',
            ease: 'power4.out'
          },
          {
            y: '+=200',
            ease: 'power4.out',
            onComplete: () => this.audio.play()
          })
        break
      default:
        return false
      }
    }
  }
}
</script>

<style lang="scss">

</style>
