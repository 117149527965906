<template>
  <div class="box">
    <p
      ref="bubble"
      class="bubble"
    >
      <slot />
    </p>
    <label>
      <button
        id="btn"
        class="is-dark"
        @click="continueTxt"
      >2</button>
      <span @click="continueTxt" />
    </label>
    <!-- <label>
         <input type="radio" class="nes-radio is-dark" checked @click="continueTxt" />
         <span></span>
       </label>

       <label>
         <input type="radio" class="nes-radio is-dark" />
         <span></span>
       </label> -->
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  data () {
    return {
      bubble: '',
      animationStatus: ''
    }
  },

  mounted () {
    this.bubble = this.$refs.bubble
    this.bubble.innerHTML = this.spanWrapText(this.bubble)
    /* console.log(this.bubble) */
    this.bubble.spans = this.bubble.querySelectorAll('span')
    /* console.log(this.bubble.spans) */

    const spans = this.bubble.spans

    /* eslint-disable */
    /*let b = []
    for (const i of a) {
        b.push(i)
    }
    console.log(b) */
    /* eslint-disable */

    const spansArray = [...spans]

    const a = spansArray.slice(0, 180)
    const b = spansArray.slice(180, 250)
    const c = spansArray.slice(250, 350)
    const d = spansArray.slice(350)

    /* console.log(a) */

    this.tl = gsap.timeline()
    this.tl2 = gsap.timeline({ paused: true })
    this.tl3 = gsap.timeline({ paused: true })
    this.tl4 = gsap.timeline({ paused: true })
    
    this.tl.from(a, {
      opacity: 0,
      display: 'none',
      duration: 0.01,
      delay: 0.5,
      stagger: 0.02,
    })
    if (b.length) {
      this.tl2.from(b, {
          opacity: 0,
          display: 'none',
          duration: 0.01,
          delay: 0.5,
          stagger: 0.02,
          onComplete: () => {
            this.animationStatus = 1
            /* a.forEach( (i) => {
              i.remove()
            }) */
          }
        })
    }
    if (c.length) {
      this.tl3.from(c, {
        opacity: 0,
        display: 'none',
        duration: 0.01,
        delay: 0.5,
        stagger: 0.02,
        onComplete: () => {
          this.animationStatus = 2
        }
      })
    }
    if (d.length) {
      this.tl4.from(d, {
      opacity: 0,
      display: 'none',
      duration: 0.01,
      delay: 0.5,
      stagger: 0.02,
      onComplete: () => {
        this.animationStatus = 3
      }
    })
    }
  },

  methods: {
    spanWrapText (target) {
      const nodes = [...target.childNodes]
      let returnText = ''

      for (const node of nodes) {
        if (node.nodeType === 3) {
          const text = node.textContent.replace(/\r?\n/g, '')
          const splitText = text.split('')
          for (const char of splitText) {
            returnText += `<span>${char}</span>`
          }
        } else {
          returnText += node.outerHTML
        }
      }
      return returnText
    },

    continueTxt () {
      /* this.tl.paused( !this.tl.paused() ) */
      if( this.tl2.paused() === true ) {
        this.tl2.resume()
      } else if( this.animationStatus === 1 ) {
        this.tl3.resume()
      } else if( this.animationStatus === 2 ) {
        this.tl4.resume()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  #btn {
    margin-right: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    border: 0;
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
  }

  #btn + span {
    position: relative;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
  }

  #btn + span::before {
    position: absolute;
    top: -2px;
    left: -20px;
    content: '';
    width: 2px;
    height: 2px;
    color: #212529;
    box-shadow: 2px 2px,4px 2px,2px 4px,4px 4px,6px 4px,8px 4px,2px 6px,4px 6px,6px 6px,8px 6px,10px 6px,2px 8px,4px 8px,6px 8px,8px 8px,10px 8px,12px 8px,2px 10px,4px 10px,6px 10px,8px 10px,10px 10px,2px 12px,4px 12px,6px 12px,8px 12px,2px 14px,4px 14px;
  }

  @supports (-moz-appearance: meterbar) {
    #btn + span::before {
      box-shadow: 2px 2px 0 0.020em,4px 2px 0 0.020em,2px 4px 0 0.020em,4px 4px 0 0.020em,6px 4px 0 0.020em,8px 4px 0 0.020em,2px 6px 0 0.020em,4px 6px 0 0.020em,6px 6px 0 0.020em,8px 6px 0 0.020em,10px 6px 0 0.020em,2px 8px 0 0.020em,4px 8px 0 0.020em,6px 8px 0 0.020em,8px 8px 0 0.020em,10px 8px 0 0.020em,12px 8px 0 0.020em,2px 10px 0 0.020em,4px 10px 0 0.020em,6px 10px 0 0.020em,8px 10px 0 0.020em,10px 10px 0 0.020em,2px 12px 0 0.020em,4px 12px 0 0.020em,6px 12px 0 0.020em,8px 12px 0 0.020em,2px 14px 0 0.020em,4px 14px 0 0.020em;
    }
  }

  #btn + span::before, #btn:focus + span::before {
    animation: blink 1s infinite steps(1);
  }

  #btn:focus + span::before {
    width: 2px;
    height: 2px;
    color: #adafbc;
    box-shadow: 2px 2px,4px 2px,2px 4px,4px 4px,6px 4px,8px 4px,2px 6px,4px 6px,6px 6px,8px 6px,10px 6px,2px 8px,4px 8px,6px 8px,8px 8px,10px 8px,12px 8px,2px 10px,4px 10px,6px 10px,8px 10px,10px 10px,2px 12px,4px 12px,6px 12px,8px 12px,2px 14px,4px 14px;
  }

  @supports (-moz-appearance: meterbar) {
    #btn:focus + span::before {
      box-shadow: 2px 2px 0 0.020em,4px 2px 0 0.020em,2px 4px 0 0.020em,4px 4px 0 0.020em,6px 4px 0 0.020em,8px 4px 0 0.020em,2px 6px 0 0.020em,4px 6px 0 0.020em,6px 6px 0 0.020em,8px 6px 0 0.020em,10px 6px 0 0.020em,2px 8px 0 0.020em,4px 8px 0 0.020em,6px 8px 0 0.020em,8px 8px 0 0.020em,10px 8px 0 0.020em,12px 8px 0 0.020em,2px 10px 0 0.020em,4px 10px 0 0.020em,6px 10px 0 0.020em,8px 10px 0 0.020em,10px 10px 0 0.020em,2px 12px 0 0.020em,4px 12px 0 0.020em,6px 12px 0 0.020em,8px 12px 0 0.020em,2px 14px 0 0.020em,4px 14px 0 0.020em;
    }
  }

  #btn + span {
    color: #fff;
  }

  #btn + span::before {
    /* stylelint-disable-line no-descending-specificity */
    color: #fff;
  }

  #btn span::before {
    width: 2px;
    height: 2px;
    color: #adafbc;
    box-shadow: 2px 2px,4px 2px,2px 4px,4px 4px,6px 4px,8px 4px,2px 6px,4px 6px,6px 6px,8px 6px,10px 6px,2px 8px,4px 8px,6px 8px,8px 8px,10px 8px,12px 8px,2px 10px,4px 10px,6px 10px,8px 10px,10px 10px,2px 12px,4px 12px,6px 12px,8px 12px,2px 14px,4px 14px;
    color: #fff;
  }

  @supports (-moz-appearance: meterbar) {
    #btn span::before {
      box-shadow: 2px 2px 0 0.020em,4px 2px 0 0.020em,2px 4px 0 0.020em,4px 4px 0 0.020em,6px 4px 0 0.020em,8px 4px 0 0.020em,2px 6px 0 0.020em,4px 6px 0 0.020em,6px 6px 0 0.020em,8px 6px 0 0.020em,10px 6px 0 0.020em,2px 8px 0 0.020em,4px 8px 0 0.020em,6px 8px 0 0.020em,8px 8px 0 0.020em,10px 8px 0 0.020em,12px 8px 0 0.020em,2px 10px 0 0.020em,4px 10px 0 0.020em,6px 10px 0 0.020em,8px 10px 0 0.020em,10px 10px 0 0.020em,2px 12px 0 0.020em,4px 12px 0 0.020em,6px 12px 0 0.020em,8px 12px 0 0.020em,2px 14px 0 0.020em,4px 14px 0 0.020em;
    }
  }

  #btn:focus + span::before {
    width: 2px;
    height: 2px;
    color: #adafbc;
    box-shadow: 2px 2px,4px 2px,2px 4px,4px 4px,6px 4px,8px 4px,2px 6px,4px 6px,6px 6px,8px 6px,10px 6px,2px 8px,4px 8px,6px 8px,8px 8px,10px 8px,12px 8px,2px 10px,4px 10px,6px 10px,8px 10px,10px 10px,2px 12px,4px 12px,6px 12px,8px 12px,2px 14px,4px 14px;
    color: #fff;
  }

  @supports (-moz-appearance: meterbar) {
    #btn:focus + span::before {
      box-shadow: 2px 2px 0 0.020em,4px 2px 0 0.020em,2px 4px 0 0.020em,4px 4px 0 0.020em,6px 4px 0 0.020em,8px 4px 0 0.020em,2px 6px 0 0.020em,4px 6px 0 0.020em,6px 6px 0 0.020em,8px 6px 0 0.020em,10px 6px 0 0.020em,2px 8px 0 0.020em,4px 8px 0 0.020em,6px 8px 0 0.020em,8px 8px 0 0.020em,10px 8px 0 0.020em,12px 8px 0 0.020em,2px 10px 0 0.020em,4px 10px 0 0.020em,6px 10px 0 0.020em,8px 10px 0 0.020em,10px 10px 0 0.020em,2px 12px 0 0.020em,4px 12px 0 0.020em,6px 12px 0 0.020em,8px 12px 0 0.020em,2px 14px 0 0.020em,4px 14px 0 0.020em;
    }
  }
  label {
    float: right;
  }
  p {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .box {
    overflow: hidden;
  }

</style>
