import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/AppHome.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AppAbout.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/AppHistory.vue')
  },
  {
    path: '/limited',
    name: 'Limited',
    component: () => import('../views/AppLimited.vue')
  },
  {
    path: '/goshinboku',
    name: 'Goshinboku',
    component: () => import('../views/AppGoshinboku.vue')
  },
  {
    path: '/gokito',
    name: 'Gokito',
    component: () => import('../views/AppGokito.vue')
  },
  {
    path: '/gaisai',
    name: 'Gaisai',
    component: () => import('../views/AppGaisai.vue')
  },
  {
    path: '/goshuin',
    name: 'Goshuin',
    component: () => import('../views/AppGoshuin.vue')
  },
  {
    path: '/jinjamap',
    name: 'Jinjamap',
    component: () => import('../views/AppJinjamap.vue')
  },
  {
    path: '/ujikomap',
    name: 'Ujikomap',
    component: () => import('../views/AppUjikomap.vue')
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/suwa',
    name: 'Suwa',
    component: () => import('../views/AppSuwa.vue')
  },
  {
    path: '/*',
    name: 'notfound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  router.referrer = from
  next()
})

export default router
