<template>
  <div>
    <div
      id="battle"
      ref="battle"
      class="box"
    >
      <img
        id="enemy"
        src="@/assets/img/crow.gif"
        alt=""
      >
      <div id="comand">
        <p>やたがらすがあらわれた</p>
        <ul>
          <li @click="fight">
            導かれる
          </li>
          <li @click="runAway">
            にげる
          </li>
        </ul>
      </div>
    </div>
    <div
      id="bg"
      ref="bg"
    />
  </div>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useCookies } from 'vue3-cookies'

export default {

  data () {
    return {
      flug: this.$store.state.flug,
      audio: new Audio(require('@/assets/guha.mp3')),
      firstVisit: false
    }
  },

  mounted() {
    const { cookies } = useCookies(); 
    gsap.registerPlugin(ScrollTrigger)

    /* const now = new Date()
    now.setSeconds(now.getSeconds() + 5)

    console.log(now)
    console.log(now.toUTCString()) */

    /* console.log(this.$cookies.get('f_vs')) */

    /* set cookies for first visit */
    if (!cookies.get('f_vs')) {
      cookies.set('f_vs', true)
      this.firstVisit = true
    }

    /* const el2 = this.$refs.battle */

    /* const bh = document.getElementsByTagName('body') */
    /* console.log(bh.clientHeight) */
    if (this.flug === 0 && this.firstVisit === true) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#battle',
          start: 'center center',
          end: '+=3000',
          /* end: `+=${el2.clientHeight}`, */
          /* toggleClass: { targets: '#enemy', className: 'blur' }, */
          pin: true
        }
      })
      tl.from('#bg', {
        autoAlpha: 0
      })
      tl.from('#battle', {
        opacity: 0,
        delay: 1
      })
        .to('#enemy', {
          className: '+=blur'
        })
    } else {
      gsap.set('#bg', {
        autoAlpha: 0
      })
      gsap.set('#battle', {
        autoAlpha: 0
      })
    }
  },

  methods: {
    fight () {
      const bg = this.$refs.bg
      this.audio.play()
      const tl = gsap.timeline()
      tl.to('#enemy', {
        x: 1000,
        y: -1000,
        ease: 'power4.inOut',
        duration: 3,
        autoAlpha: 0
      })
        .to('#battle', {
          autoAlpha: 0,
          delay: 2
        })
        .to('#bg', {
          autoAlpha: 0,
          onComplete: () => {
            bg.remove()
            const pin = document.querySelector('.pin-spacer')
            pin.remove()
          }
        })
      this.$store.dispatch('flugup')
      /* console.log(this.flug) */
    },

    runAway () {
      const bg = this.$refs.bg
      this.audio.play()
      const tl = gsap.timeline()
      tl.to('#enemy', {
        className: '+=blink'
      })
        .to('#battle', {
          autoAlpha: 0,
          delay: 2
        })
        .to('#bg', {
          autoAlpha: 0,
          onComplete: () => {
            bg.remove()
            const pin = document.querySelector('.pin-spacer')
            pin.remove()
          }
        })
      this.$store.dispatch('flugup')
      /* console.log(this.flug) */
    }
  }
}
</script>

<style lang="scss" scoped>
  p, li {
      font-family: 'NuKinakoMochi-BarVtE-1';
  }
  #battle {
    z-index: 101;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      &.blur {
        animation: blur 2s forwards;
      }
      &.blink {
        animation: blink .5s 10 steps(1);
      }
    }

    #comand
      p {
        margin: 1rem 0;
      }
      ul {
      border: 6px solid #fff;
      background: #000;
      border-radius: 6px;
      margin-bottom: 1.5rem;
      padding: 1rem 1.5rem;
      box-sizing: border-box;
      li {
        margin: .75rem 0;
        display: block;
        padding-left: 2rem;
        color: #fff;
        &:hover {
          background: url("~@/assets/img/arw.svg") no-repeat 0 0.15rem;
          background-size: 13px;
          text-decoration: none;
        }
      }
    }
  }

  #bg {
    width: 100vw;
    height: 100%;
    background: #333;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
    &::after {
      content: '';
      width: 100vw;
      height: 100%;
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      animation: blink 1s 10 steps(1);
    }
  }

  @keyframes blur {
    0% {
      filter: blur(1.5rem);
    }
    100% {
      filter: blur(0);
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
</style>
