<template>
  <div class="drwer-guji">
    <img
      v-if="num >= 0 && num <= 11999"
      src="@/assets/guji_L1.gif"
      alt=""
    >
    <img
      v-if="num >= 12000 && num <= 12299"
      src="@/assets/guji_L2.gif"
      alt=""
    >
    <img
      v-if="num >= 12300 && num <= 12599"
      src="@/assets/guji_L3.gif"
      alt=""
    >
    <img
      v-if="num >= 12600 && num <= 12899"
      src="@/assets/guji_L4.gif"
      alt=""
    >
    <img
      v-if="num >= 12900 && num <= 13099"
      src="@/assets/guji_L5.gif"
      alt=""
    >
    <img
      v-if="num >= 13100 && num <= 13499"
      src="@/assets/guji_L6.gif"
      alt=""
    >
    <img
      v-if="num >= 13500 && num <= 13899"
      src="@/assets/guji_L7.gif"
      alt=""
    >
    <img
      v-if="num >= 13900 && num <= 14599"
      src="@/assets/guji_L8.gif"
      alt=""
    >
    <img
      v-if="num >= 14600 && num <= 20999"
      src="@/assets/guji_L9.gif"
      alt=""
    >
    <img
      v-if="num >= 21000"
      src="@/assets/guji_L10.gif"
      alt=""
    >
    <img
      src="@/assets/img/guji_name.svg"
      alt="宮司 水澤貴文"
    >
    <div class="guji-point">
      <div
        ref="heartButton"
        class="guji_heart likeButton"
        href="#"
        :class="{ 'clicked' : isActive }"
        @click="toggleClass"
      >
        <img
          src="@/assets/img/icn_heart.svg"
          class="heart"
          alt=""
        >
      </div>
      <dl>
        <dt>LEVEL</dt><dd>{{ currentLevel }}</dd>
        <dt>EXP.</dt><dd id="counter">
          {{ returnData }}
        </dd>
        <dt>Req.</dt><dd>{{ requiredEXP }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import gsap from 'gsap'

export default {
  data () {
    return {
      apidata: null,
      num: 0,
      isActive: false,
      endpoint_post: process.env.VUE_APP_ENDPOINT_POST,
      endpoint_get: process.env.VUE_APP_ENDPOINT_GET,
      token: '',
      expires_at: null,
      audio: new Audio(require('@/assets/levelup.mp3')),
      neededEX: [12000, 12300, 12600, 12900, 13100, 13500, 13900, 14600, 21000],
      currentLevel: 1,
      toggleBtn: false
    }
  },

  computed: {
    returnData () {
      return this.apidata
    },

    requiredEXP () {
      const expRequired = this.neededEX[this.currentLevel - 1] - this.num
      return expRequired
    }
  },

  async mounted () {
    this.counter = document.getElementById('counter')
    const endpoint = this.endpoint_get
    this.a = [...this.neededEX]

    // 経験値を取得する
    try {
      // トークン取得
      const { data: tokenData } = await axios.get(`${endpoint}?action=generate_token`)
      this.token = tokenData.token;
      this.expires_at = tokenData.expires_at;
      console.log("取得したトークン:", this.token);

      // トークンを使ってAPIリクエストを送信
      const { data: responseData} = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`
        }
      })

      console.log("データ:", responseData);
      this.num += responseData.num
      this.apidata = responseData.num
      this.currentLevel = responseData.currentLevel
      console.log(responseData.num)
    } catch (err) {
      console.error("エラー:", err);
    }
  },

  methods: {
    async countUp() {
      await this.fetchData()
      this.counter.innerHTML = ++this.num
      const lnum = this.currentLevel - 1
      if (this.num >= this.a[lnum]) {
        this.currentLevel += 1
      }
      this.postData()
    },

    countReset () {
      this.num = 0
      this.counter.innerHTML = this.num
      this.postData()
    },

    f () {
      this.isActive = false
    },

    toggleClass (e) {
      this.isActive = true
      this.countUp()
      const ab = e.target
      ab.addEventListener('animationend',
        () => {
          /* alert('animationend') */
          this.isActive = false
        }
      )
      if (this.neededEX.includes(this.num)) {
        this.audio.play()
      }
    },

    // 最新の経験値を取得する
    async fetchData() {
      try {
        // トークンを使ってAPIリクエストを送信
        const { data: responseData} = await axios.get(this.endpoint_get, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        })

        console.log("データ:", responseData);
        this.num = responseData.num
        this.apidata = responseData.num
        this.currentLevel = responseData.currentLevel
        console.log(responseData.num)
      } catch (err) {
        console.error("エラー:", err);
      }
    },

    // 経験値をPOSTする
    async postData() {
      const dataToSend = {
        num: this.num,
        withCredentials: true,
        currentLevel: this.currentLevel
      }
      try {
        const { data: responseData } = await axios.post(this.endpoint_post, dataToSend, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        })
        this.responseMessage = responseData.message;
        console.log(this.responseMessage)
      }
      catch (err) {
        if (err.response) {
          this.responseMessage = `エラー: ${err.responseData.err}`;
        } else {
          this.responseMessage = '通信エラー';
          console.log(err)
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    postLevelData () {
      axios.post(this.endpoint1, {
        currentLevel: this.currentLevel
      })
        .then(function () {
          /* console.log(response) */
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    openBox () {
      gsap.to('.drwer-guji', {
        height: 'auto',
        duration: 0.2
      })
      gsap.to('.drwer-guji > *', {
        autoAlpha: 1
      })
    },

    open () {
      this.toggleBtn = !this.toggleBtn
    }
  }
}
</script>

<style scoped lang="scss">

@use "sass:list";

// アニメーションの時間
$animateTime: 800ms;
// パーティクルの数
$particleNum: 14;

@mixin centering {
  transform-origin: 250px 250px;
}

@mixin animationFill {
  animation-fill-mode: forwards;
}

.likeButton {
  cursor: pointer;

  .border {
    fill: rgb(255, 255, 255);
  }
  .explosion {
    transform-origin: 250px 250px;
    transform: scale(0.02);
    stroke: rgba(221, 70, 136, 1);
    fill: none;
    opacity: 0;
    stroke-width: 1;
    @include centering;
  }

  .particleLayer {
    opacity: 0;

    circle {
      opacity: 0;
      @include centering;
    }
  }

  &.clicked {
    .explosion {
      animation: explosionAnime $animateTime;
      @include animationFill;
    }

    .heart {
      animation: heartAnime $animateTime;
      @include animationFill;
    }

    .particleLayer {
      animation: particleLayerAnime $animateTime;
      @include animationFill;

      @for $index from 1 through $particleNum {
        circle:nth-child(#{$index}) {
          animation: particleAnimate#{$index} $animateTime;
          @include animationFill;
        }
      }
    }
  }
}

// 爆発のアニメーション
@keyframes explosionAnime {
  0% {
    opacity: 0;
    transform: scale(0.01);
  }
  1% {
    opacity: 1;
    transform: scale(0.01);
  }

  5% {
    stroke-width: 200;
  }

  20% {
    stroke-width: 300;
  }

  50% {
    stroke: rgba(204, 142, 245, 1);
    transform: scale(1.1);
    stroke-width: 1;
  }
  50.1% {
    stroke-width: 0;
  }

  100% {
    stroke: rgba(204, 142, 245, 1);
    transform: scale(1.1);
    stroke-width: 0;
  }
}

// パーティクル全体のアニメーション
@keyframes particleLayerAnime {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }

  60% {
    transform: translate(0, 0);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(0, -20px);
  }
}

// パーティクルの個別アニメーション

// 各点の移動位置
// 1点目のx座標, 1点目のy座標, 2点目のx座標, 2点目のy座標,...と並んでいる
$points: -16, -59,
41, 43,
50, -48,
-39, 36,
-39, 32,
48, 6,
-69, -36,
-12, -52,
-43, -21,
-10, 47,
66, -9,
40, -45,
29, 24,
-10, 50;

$pointer: 1;

@for $index from 1 through $particleNum {
  @keyframes particleAnimate#{$index} {
    0% {
      transform: translate(0, 0);
    }
    30% {
      opacity: 1;
      transform: translate(0, 0);
    }
    80% {
      transform: translate(#{list.nth($points, $pointer)}px, #{list.nth($points, $pointer + 1)}px);
    }
    90% {
      transform: translate(#{list.nth($points, $pointer)}px, #{list.nth($points, $pointer + 1)}px);
    }
    100% {
      opacity: 1;
      transform: translate(#{list.nth($points, $pointer)}px, #{list.nth($points, $pointer + 1)}px);
    }
  }
  $pointer: $pointer + 2;
}

// ハートのアニメーション
@keyframes heartAnime {
  0% {
    transform: scale(0);
  }
  39% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(1.2, 1.2);
  }
  70% {
    transform: scale(1, 1) translate(0%, -10%);
  }
  75% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  80% {
    transform: scale(0.95, 1.05) translate(0%, -3%);
  }
  100% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }
}
</style>
