import { createApp } from 'vue'
import VueGtm from 'vue-gtm'
import { VueCookies } from 'vue3-cookies'
import App from './App.vue'
import router from './router'
import store from './store'

require('@/assets/sass/main.scss')
require('@/assets/js/common.js')

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueCookies);
app.use(VueGtm, {
  id: 'GTM-PXF75RQ',
  router,
});

app.mount('#app');